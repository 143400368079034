import { commonConfig } from "@cyna/common/config"
import {
  DEFAULT_LANGUAGE,
  NAMESPACES,
  SUPPORTED_LANGUAGES,
} from "@cyna/common/constants"
import { InitOptions } from "i18next"

const isServer = typeof window === "undefined"

export const i18nextConfig: InitOptions = {
  backend: { loadPath: "/i18n/{{lng}}/{{ns}}.json" },
  cleanCode: true,
  defaultNS: "common",
  detection: { order: ["path", "htmlTag", "cookie", "navigator"] },
  fallbackLng: DEFAULT_LANGUAGE,
  fallbackNS: "common",
  interpolation: {
    escapeValue: false,
  },
  // eslint-disable-next-line no-undefined
  lng: commonConfig.env.isTest ? DEFAULT_LANGUAGE : undefined,
  ns: NAMESPACES,
  preload: isServer ? SUPPORTED_LANGUAGES : [DEFAULT_LANGUAGE],
  supportedLngs: SUPPORTED_LANGUAGES,
}
